import { Pipe, PipeTransform } from '@angular/core';
import { TourOptions } from '../types/leasing-fallbacks';
import { getTourTypeOptionName } from '../utils/leasing.utils';
import { TourTypesOptions } from '../core/enums/leasing.enum';

@Pipe({
  name: 'tourOptionsFilter',
})
export class TourOptionsFilterPipe implements PipeTransform {
  transform(tourOptions: TourOptions): string[] {
    if (!tourOptions) {
      return [];
    }

    return Object.entries(tourOptions)
      .filter(([_, option]) => option.allowed)
      .map(
        ([key, option]) =>
          `${getTourTypeOptionName(key as TourTypesOptions)}${option.priority !== undefined ? ` - Priority ${option.priority}` : ''}`
      );
  }
}
