export enum RoomType {
  BEDROOM = 'bedroom',
  BATHROOM = 'bathroom',
}

export enum OccupancyType {
  CONVENTIONAL = 'Conventional',
  STUDENTS = 'Students',
}

export enum UnitAvailabilityOptions {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Not Available',
}

export enum AvailableUnitStatus {
  AVAILABLE_ON_NOTICE = 'On Notice (Available)',
  AVAILABLE_VACANT_READY = 'Vacant Ready (Available)',
  AVAILABLE_VACANT_NOT_READY = 'Vacant Not Ready (Available)',
}

export enum UnavailableUnitStatus {
  UNAVAILABLE_ON_NOTICE = 'On Notice (Unavailable)',
  UNAVAILABLE_VACANT_READY = 'Vacant Ready (Unavailable)',
  UNAVAILABLE_OCCUPIED = 'Occupied (Unavailable)',
  UNAVAILABLE_VACANT_NOT_READY = 'Vacant Not Ready (Unavailable)',
}

export enum IncomingMessageSenderType {
  TENANT = 'tenant',
  LEAD = 'lead',
}

export enum OptFields {
  PHONE = 'Phone',
  EMAIL = 'Email',
}

export enum LeasingRightPanel {
  UNITS_AVAILABILITY,
  GENERAL_DATA,
  TOUR_DATA,
  AMENITIES,
}

export enum TourTypesIDs {
  GUIDED = 1,
  SELF_GUIDED = 2,
  VIRTUAL = 3,
}

export enum TourTypesNames {
  GUIDED = 'Guided',
  SELF_GUIDED = 'Self-guided',
  VIRTUAL = 'Virtual',
}

export enum TourTypesOptions {
  GUIDED = 'guided',
  SELF_GUIDED = 'selfGuided',
  VIRTUAL = 'virtual',
}

export enum LeasingFocus {
  GUIDED_TOUR = 'GuidedTour',
  SELF_GUIDED_TOUR = 'SelfGuidedTour',
  FILL_IN_APPLICATION = 'FillInApplication',
}

export enum TourSchedulingMethod {
  VENDOR = 'vendor',
  COLLEEN = 'colleen',
}
