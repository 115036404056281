import moment from 'moment';
import { TourData } from '../types/leasing-fallbacks';
import { TimeSlot } from '../types/leasing.model';
import datesUtils from './dates.utils';
import { TourTypesNames, TourTypesOptions } from '../core/enums/leasing.enum';

export function getTourDateString(tourDateSettings: TourData): string {
  if (tourDateSettings?.isScheduledTour) {
    const tourDay = moment(tourDateSettings.tourDateString).format('MMM DD, YYYY');
    const tourFromTimeString = moment(tourDateSettings.tourFromTimeString, 'HH:mm:ss').format('HH:mm');
    const tourToTimeString = moment(tourDateSettings.tourToTimeString, 'HH:mm:ss').format('HH:mm');
    const timezoneAbbr = datesUtils.getTimezoneAbbreviation(tourDateSettings.timezone);
    const tourDate = `${tourDay}\n${tourFromTimeString} — ${tourToTimeString} ${timezoneAbbr}`;
    return tourDate;
  } else {
    return null;
  }
}

export function formatTourSlots(slots: TimeSlot[]): string {
  return slots
    .map((slot) => {
      const start = moment.utc(`${slot.date} ${slot.startTime}`, 'YYYY-MM-DD HH:mm:ss');
      const end = moment.utc(`${slot.date} ${slot.endTime}`, 'YYYY-MM-DD HH:mm:ss');

      // Format date as YYYY-MM-DD and time as HH:mm
      return `${start.format('YYYY-MM-DD')}, ${start.format('HH:mm')} - ${end.format('HH:mm')}`;
    })
    .join('\n');
}

/**
 * Converts a tour type option to its corresponding display name.
 *
 * @param tourTypeOption - The tour type option to convert (e.g. 'guided', 'selfGuided', 'virtual')
 * @returns The human-readable name of the tour type (e.g. 'Guided', 'Self-guided', 'Virtual')
 *
 * @example
 * getTourTypeOptionName(TourTypesOptions.GUIDED) // returns 'Guided'
 * getTourTypeOptionName(TourTypesOptions.SELF_GUIDED) // returns 'Self-guided'
 */
export function getTourTypeOptionName(tourTypeOption: TourTypesOptions): TourTypesNames {
  switch (tourTypeOption) {
    case TourTypesOptions.GUIDED:
      return TourTypesNames.GUIDED;
    case TourTypesOptions.SELF_GUIDED:
      return TourTypesNames.SELF_GUIDED;
    case TourTypesOptions.VIRTUAL:
      return TourTypesNames.VIRTUAL;
    default:
      return TourTypesNames.GUIDED;
  }
}
